import React from 'react'
//import { Link } from 'gatsby'
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Logo from '../Logo'
import mailIconDark from '../../assets/media/mailIconDark.svg'
import mailIconWhite from '../../assets/media/mailIconWhite.svg'
export default class Navbar extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      menuIsActive: false,
      menu: this.props.menu,
      menuItems: null
    }
  }  

  componentDidMount() {
    const { menu } = this.state
    const { theme } = this.props 
    const menuItems = menu.map((item, i) => (
      <AniLink 
        cover
        className={`navbar-item menu-item button`}
        to={item.url}
        href={item.url}
        key={`menuitem_${i}`}
        bg="#FA7C91"
        duration={0.6}
        direction="up"
      >
        <div className="content">
          <div className="button-bg"></div>
          <span className="mailIcon">
            <img src={mailIconDark} className="iconlight" alt="" height={20} width={20} />
            <img src={mailIconWhite} className="icondark" alt="" height={20} width={20} />
          </span>
          <span>
          {item.title}
          </span>
          
        </div>
      </AniLink>
    ))
    this.setState({menuItems: menuItems})
  }

  toggleMenu = (e) => {
    this.setState({
      menuIsActive: !this.state.menuIsActive
    })
  }

  isMenuActive() {
    if (this.state.menuIsActive) {
      return 'is-active'
    }
    return ''
  }

  render() {   
    const { menuItems } = this.state
    const { logo, theme, isScrolled } = this.props
    return (
      <React.Fragment>
        <nav className={`navbar is-fixed-top drop-shadow theme-${theme} ${isScrolled ? 'scrolled' : ''}`}>
          <div className={`navbar-bg`}></div>
          <div className="nav">
            <div className="navbar-brand">
              <Logo logo={logo} />
            </div>
            <div className={`nav-align-end`}>
                {menuItems}
            </div>
          </div>
        </nav>
    </React.Fragment>
    )
}
}