// throttle a function so it only fires every
// X ms based on the parameter wait
// source: https://gist.github.com/peduarte/969217eac456538789e8fac8f45143b4
const throttle = (func, wait = 100) => {
    let timer = null;
    if (timer === null) {
        timer = setTimeout(() => {
            func()
            timer = null;
        }, wait);
    }
  }

export default throttle