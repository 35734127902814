import React from "react";

const Animate = ({children, className, device}) => {

    const style = {}

    if (device === 'desktop') {
        style.opacity = 0
    }

    return (
        <div className={`animated ${className}`} style={style}>{children}</div>
    )
}

export default Animate;