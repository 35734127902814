import React from 'react'
import Img from "gatsby-image"
import throttle from '../throttle'

/**
 * Iterate over each section and produce 
 * the appropriate Element
 */
class Section extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            visibility: 'invisible',
            hydrate: false,
            asset: false,
        }
        //this.setVisible = this.setVisible.bind(this)
    }

    componentDidMount() {

        const { layout } = this.props
        import(`./${layout}`)
        .then((module) => { 
          this.setState({
            module: module.default,
            hydrate: true,
          })
        });
    }

    componentDidUpdate() {
        const { visibility: visibleState } = this.state
        const { visibility } = this.props

        // update visibility state if different from props
        if (visibleState !== visibility) {
            this.setState({
                visibility: visibility
            })
        }
    }

    render () {
        const { forceVisible, classes, css, video, gradient, theme, classTheme, image, device, name, id, layout, content } = this.props
        const { ref, hydrate, module: Module } = this.state
        let { visibility: visibleState } = this.state

        // force visibility if forceVisible is true
        if (forceVisible === true) {
            visibleState = 'visible'
        }

        let videoBackground = null
        
        if (video) {
            videoBackground = <video autoPlay="autoplay" muted="muted" poster={video.poster[device].src} loop="loop"> 
                                <source src={video.src} type="video/mp4" />
                            </video>
            // provide a fallback if device is desktop
            // and we're not pre-rendering
            if (device !== 'desktop') {
                videoBackground = <Img fixed={video.poster[device]} className="videoFallback" objectFit="cover" objectPosition="50% 50%" />
                //videoBackground = <video muted="muted" poster={video.poster[device].src}></video>
            }
        }

        let imageBackground = null
        if (image) {
            imageBackground = <Img className="bg" fixed={image.src[device]} objectFit={image.fit} />
        }
        

        let overlay = null
        if (gradient) {
            overlay = {
                background: `linear-gradient(150deg, ${gradient.from}, ${gradient.to})`
            }
        }

        let Layout = null
        if (hydrate) {
            Layout = <Module device={device} visibility={visibleState} key={`content_${id}`} content={content} />
        }

        return (
            <section id={id} className={`section ${classes} ${visibleState} ${classTheme || theme}`} style={css} ref={ref} theme={theme} name={name ? name : null} device={device}>
                <div className="bgWrap" device={device}>
                    <div className="video" device={device}>{videoBackground}</div>
                    <div className="image" device={device}>{imageBackground}</div>
                    <div className="overlay" device={device} style={overlay}></div>  
                </div>
                <div className="container">
                    {Layout}
                </div>
            </section>
        )
    }
}

export default Section;

export class SectionScroller extends React.Component {
    
    /**
     * The state properties are defined
     * as follows
     * 
     * - active: which child component is active
     * - container: the parent component bounding rect
     * - sections: a list of the child component bounding rect
     * - sidebar: the DOM of the sidebar used for navigation
     */
    constructor(props) {
        super(props)
        this.state = {
            active: 0,
            container: {},
            sections: [],
            sidebar: null,
            updateSidebar: false,
            updateState: false,
            scrollY: 0,
            theme: 'dark',
            childrenMounted: true,
        }
        this.sectionrefs = []
        this.scrollerref = React.createRef()
    }

    componentDidMount() {
        this.updateBoundingRect()
        this.createSidebar()
        //this.forceUpdate()

        this.setState({
            mount: true,
            childrenMounted: false
        })
    }

    componentDidUpdate() {
        const { mount, childrenMounted } = this.state
        const children = this.sectionrefs
        // child components have not been mounted
        // within the parent via ref
        if (mount && !childrenMounted) {
            let mounted = true
            children.map((child) => {
                if (child.current === null) {
                    mounted = false
                }
            })

            // if all children are mounted
            if (mounted) {
                const { device } = this.props
                if (device === 'desktop') {
                    window.addEventListener('resize', this.updateBoundingRect)
                    window.addEventListener('resize', this.getContainerForChildren)
                    window.addEventListener('scroll', this.onScrollVisibility)
                }
                this.getContainerForChildren()
            }
        }
        // configure from child refs
        this.updateChildBoundaries()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateBoundingRect)
        window.removeEventListener('resize', this.getContainerForChildren)
        window.removeEventListener('scroll', this.onScrollVisibility)
    }

    updateChildBoundaries = () => {
        const { setTheme, setisScrolled, headerScrollStart } = this.props
        const { updateState, active, activeHeaderIntersect, scrollY, updateSidebar } = this.state
        const sections = this.sectionrefs

        // only run the rest if the last section ref
        // has mounted
        if (updateState) {
            // update header theme
            const headerTheme = sections[activeHeaderIntersect].current.props.theme
            const headerScrollHeight = headerScrollStart || 500
            setTheme(headerTheme);
            setisScrolled(scrollY > headerScrollHeight);

            // update theme based on offset from sidebar

            // update section theme
            const { theme } = sections[active].current.props
            this.setState({
                theme: theme,
                updateState: false
            })
        }

        if (updateSidebar) {
            this.createSidebar()
        }
    }

    updateBoundingRect = () => {
        const staleState = this.state

        // check that the ref has been set
        if (this.scrollerref.current) {
            const rect = this.scrollerref.current.getBoundingClientRect()

            this.setState({
                ...staleState,
                container: {
                    x: rect.x,
                    y: rect.y,
                    width: rect.width,
                    height: rect.height
                }
            })
        }
    }

    /**
     * On scroll, varify visibility
     * of any sections provided as 
     * children to this component
     */
    onScrollVisibility = (e) => {

        throttle(() => {
            // settings
            const opts = {
                detectWithin: 0.5, // offset in percent from 0 to 1
            }

            const { scrollY, innerHeight: height } = window
            const viewport = scrollY + height
            const { sections: children } = this.state
            let { active: activeChild, activeHeaderIntersect: headerChildOverlap, isScrolled } = this.state

            children.map((child, i, source) => {
                /**
                 * x, y, w, h (self-explanatory)
                 * ot = offset top
                 * ob = bottom of container
                 */
                //const { x: x2, y: y2, width: w2, height: h2 } = child
                const { y: y2, height: h2 } = child
                const ot = y2 + ( h2 * opts.detectWithin )
                if (viewport >= ot) {
                    source[i].visibility = 'visible'
                    activeChild = i
                }

                // check if the header overlaps
                // this is done independently
                const top = y2
                if (scrollY >= top) {
                    headerChildOverlap = i
                }
            })

            this.setState({
                sections: children,
                active: activeChild,
                activeHeaderIntersect: headerChildOverlap,
                updateSidebar: true,
                updateState: true,
                scrollY: scrollY
            })
        })
    }

    getContainerForChildren = () => {
        const sections = this.sectionrefs
        const containers = []

        sections.map(section => {
            const { current } = section
            if ( current !== null ) {
                const el = document.getElementById(`section_${current.props.idx}`)
                if (el) {
                    el.getBoundingClientRect()
                    //const boundary = current.getBoundingClientRect()
                    containers.push({
                        x: el.offsetLeft,
                        y: el.offsetTop,
                        width: el.offsetWidth,
                        height: el.offsetHeight
                    })
                }  
            }
        })

        this.setState({
            sections: containers,
            childrenMounted: true,
        })

        return containers
    }

    createSidebar = () => {
        const { device } = this.props

        let sidebar = null
        if (device === 'desktop') {
            sidebar = this.getSidebar()
        }

        this.setState({
            sidebar: sidebar,
            updateSidebar: false,
        })
    }

    /**
     * This f'n is actually pretty
     * finicky.
     * 
     * the refs have to be defined first
     * so we are waiting for the child to
     * call onMount (passed as props)
     * and then determine whether or not
     * the ref has been updated
     * then we determine that the ref exists
     * by iterating over the list and checking
     * for null values
     * FINALLY, we output the content from the
     * refs that were passed
     * 
     * In summary: don't touch the f'ns that 
     * integrate into the child component lifecycle
     * methods, they are absolutely required
     */
    getSidebar = () => {
        const { device } = this.props
        const { active, theme } = this.state
        const sections = this.sectionrefs
        const items = []

        sections.map( (section, i) => {
            const { current } = section

            if ( current !== null ) {
                const { name } = current.props
                if (name) {
                    const item = (<li className={i === active ? 'active' : ''} key={`sidebar_li_${i}`}>
                                    <span></span>
                                    <a 
                                    onClick={this.scrollToSection}
                                    idx={i}
                                    role="button" 
                                    aria-pressed="false" 
                                    >{name}</a>
                                </li>)
                    items.push(item)
                }
            }
        })
        if (device === 'desktop') {
            return (
                <ul className={`scrolling-sidebar ${theme}`}>
                    {items}
                </ul>
            )
        }
        return null
    }

    scrollToSection = (e) => {

        // if we recieve an event, use the event object
        // otherwise assume e is an index
        let idx = e
        if (e.target) {
            const elem = e.target
            idx = parseInt(elem.getAttribute('idx'))
        }

        const { active, sections } = this.state

        if (idx !== active) {
            const top = Math.floor(sections[idx].y)
            window.scrollTo({ top: top, left: 0, behavior: 'smooth' });
        }

    }

    render () {
        const { children, device } = this.props
        const { sidebar, sections } = this.state

        return (
            <div>
                {sidebar}
                {React.Children.map(children, (child, i) =>{
                    let visibility = 'invisible'
                    
                    if (sections[i] && sections[i].visibility) {
                        visibility = sections[i].visibility
                    }
                    
                    if (device !== 'desktop') {
                        visibility = 'visible'
                    }

                    // here is where you should create a ref and assign it to the child
                    // instead of passing it up
                    let ref = null
                    if (this.sectionrefs[i] && this.sectionrefs[i].ref) {
                        ref = this.sectionrefs[i].ref
                    } else {
                        ref = React.createRef()
                        this.sectionrefs[i] = ref
                    }

                    return React.cloneElement(
                        child, 
                        { 
                            device: device,
                            visibility: visibility,
                            key: i,
                            idx: i,
                            id: `section_${i}`,
                            ref: ref
                        })
                })}
            </div>
        )
    }
}