import React, { useState } from 'react'
import Helmet from 'react-helmet'

import Navbar from './Navbar'

import '../../assets/main.sass'

export default class TemplateWrapper extends React.Component {

  constructor(props) {
      super(props)
      this.state = {
        theme: 'dark',
        isScrolled: 0,
      }
  }

  setTheme = (theme) => {
    this.setState({
      theme: theme
    })
  }

  setisScrolled = (isScrolled) => {
    this.setState({
      isScrolled: isScrolled
    })
  }
  
  render() {
    const { children, title, descr, logo, device, noindex } = this.props
    const { setTheme, setisScrolled } = this
    const { theme, isScrolled } = this.state
    
    // check if this should be indexed
    const indexmeta = noindex ? <meta name="robots" content="noindex" /> : null

    const menu = [
      {
        title: 'Contact Us',
        url: "/contact"
      }
    ]

    return (
        <React.Fragment>
              <Helmet htmlAttributes={{lang:'en'}}>
                  <title>{title}</title>
                  <meta name="description" content={descr} />
                  {indexmeta}
              </Helmet>
              <Navbar menu={menu} logo={logo} theme={theme} isScrolled={isScrolled} />
              <div className={`layout-${device}`} device={device} key="layout">
                  {React.Children.map(children, child =>{
                    return React.cloneElement(child, { device: device, setTheme: setTheme, setisScrolled: setisScrolled })
                  })}
              </div>
        </React.Fragment>
    )
  }
}